
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

import { Clip } from '@/@types/graphql-types';
import { PlayerIcon } from '@/enums/player-icon';

import VideoPlayerWrapper from './VideoPlayerWrapper.vue';

const playerEvents = ['VIDEO_BUFFERING', 'VIDEO_END', 'VIDEO_PLAY', 'VIDEO_PAUSE'] as const;
type PlayerStatus = (typeof playerEvents)[number];

@Component({
	name: 'PlexPlayer',
	components: { VideoPlayerWrapper },
})
export default class PlexPlayer extends Vue {
	@Prop({ required: true }) clip: Clip;
	@Prop({ default: false }) isActive: boolean;
	@Prop({ default: false }) isBackdrop: boolean;
	@Prop({ default: null }) playIconType: PlayerIcon | null;

	get srcUrl() {
		return this.clip.sourceUrl;
	}

	@Emit('setActiveClipId')
	setActiveClipId(clipId: string | null) {
		return clipId;
	}

	async play() {
		this.stateChanged('VIDEO_PLAY');
	}

	stateChanged(playerStatus: PlayerStatus) {
		switch (playerStatus) {
			case 'VIDEO_PLAY':
				this.setActiveClipId(this.clip.externalId);
				this.$emit('onPlaying', this.clip);
				break;

			case 'VIDEO_PAUSE':
				this.$emit('onPaused', this.clip);
				break;

			case 'VIDEO_END':
				this.$emit('onEnded', this.clip);
				break;

			case 'VIDEO_BUFFERING':
				this.$emit('onBuffering', this.clip);
				break;
		}
	}
}
