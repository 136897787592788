import { StreamingChartsTitlesEdge } from '@/@types/graphql-types';

/**
 * This function returns a list of titles to be displayed in a streaming chart.
 * @param titleId - The ID of the title which the streaming chart is centred around.
 * @param titleEdges - The array of title edges that can be displayed in the streaming chart.
 * @param range - The number of titles to be displayed before and after the central title in the streaming chart.
 * @returns A list of streaming chart titles with the central title placed in the middle position of the array.
 */
export function sliceTitles(titleId: string | undefined, titleEdges: StreamingChartsTitlesEdge[], range: number) {
	const titles = titleEdges || [];
	const title = titles.find(title => title?.node?.id === titleId);
	const rank = title?.streamingChartInfo?.rank;

	// Set the start and end indices for default slicing.
	const sliceStart = range * 2 + 1 - range;
	const sliceEnd = range * 2 + 1 + range + 1;

	// If the title doesn't have a rank (e.g. is null/undefined), slice based on the fixed default range as a fallback.
	if (!rank) {
		return titles.slice(sliceStart, sliceEnd);
	}

	// Calculate the dynamic start and end indices for slicing. This is necessary when viewing titles in the top positions of the chart.
	// Dynamically calculate the beginning of the array to be sliced. If this number ends up being less than 0 (i.e. the title's rank is less than the specified range + 1), use 0.
	const dynamicSliceStart = Math.max(rank - (range + 1), 0);
	// Dynamically calculate the end of the array to be sliced. As we hit the top positions where dynamicSliceStart is 0, add the specified range (+1) on top to buff out the end of the list.
	const dynamicSliceEnd = rank + range + (dynamicSliceStart === 0 ? range + 1 - rank : 0);

	// If the rank is less than or equal to the total chart range, slice dynamically.
	if (rank <= range * 2 + 1) {
		return titles.slice(dynamicSliceStart, dynamicSliceEnd);
	}

	// Otherwise, slice based on the default fixed range.
	return titles.slice(sliceStart, sliceEnd);
}
