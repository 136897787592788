import { computed } from 'vue';
import { toValue } from '@vueuse/core';

import { useLanguageStore, useUserStore } from '@/helpers/composables/useStores';
import { useQuery } from '@/helpers/composables/useApollo';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import type { MaybeRefOrGetter } from '@vueuse/core';
import type { TitleFilter } from '@/@types/graphql-types';
import type { FromRefOrGetter, WithDefault, WithRequired } from '@/helpers/types';
import type { AssertByTypename } from '@/helpers/graphql-model-helper';
import {
	GetSimilarTitlesQuery,
	GetSimilarTitlesDocument,
	GetSimilarTitlesQueryVariables,
	GetSimilarUpcomingTitlesQuery,
	GetSimilarUpcomingTitlesDocument,
	GetSimilarUpcomingTitlesQueryVariables,
} from '@/components/graphql/queries/GetSimilarTitles.query';

interface UseSimilarTitlesOptions {
	titleId: MaybeRefOrGetter<string>;

	isUpcoming?: MaybeRefOrGetter<boolean>;
	filters?: MaybeRefOrGetter<TitleFilter>;
	/** minimum number of titles to display the module */
	minTitles?: MaybeRefOrGetter<number>;
	includeOffers?: MaybeRefOrGetter<boolean>;
}

type ReleasedSimilarTitlesNode = AssertByTypename<GetSimilarTitlesQuery['node'], 'Movie' | 'Show'>;
type ReleasedSimilarTitleNode = NonNullable<ReleasedSimilarTitlesNode['similarTitlesV2']['edges']>[number]['node'];

type ReleasedSimilarTitlesWithOffers = WithRequired<ReleasedSimilarTitleNode, 'offers'>;
type ReleasedSimilarTitlesWithoutOffers = Omit<ReleasedSimilarTitleNode, 'offers'>;

type ReleasedSimilarTitles<IncludeOffers extends boolean> = IncludeOffers extends true
	? ReleasedSimilarTitlesWithOffers[]
	: ReleasedSimilarTitlesWithoutOffers[];

type UpcomingSimilarTitlesNode = NonNullable<GetSimilarUpcomingTitlesQuery['newTitles']['edges']>[number]['node'];

type SimilarTitles<IsUpcoming extends boolean, IncludeOffers extends boolean> = IsUpcoming extends true
	? UpcomingSimilarTitlesNode[]
	: ReleasedSimilarTitles<IncludeOffers>;

export function useSimilarTitles<T extends UseSimilarTitlesOptions = UseSimilarTitlesOptions>(options: T) {
	const { country, language } = useLanguageStore();
	const { isPremium } = useUserStore();

	const { titleId, filters = {}, minTitles = 1, includeOffers = false, isUpcoming = false } = options;

	// Inferred option types
	type IncludeOffers = FromRefOrGetter<WithDefault<T['includeOffers'], false>>;
	type IsUpcoming = FromRefOrGetter<WithDefault<T['isUpcoming'], false>>;

	const baseVariables = computed(() => ({
		language: language.value,
		country: country.value,
		filters: {
			excludeIrrelevantTitles: isPremium.value,
			...toValue(filters),
		},
	}));

	const variables = computed<GetSimilarTitlesQueryVariables>(() => ({
		...baseVariables.value,
		includeOffers: toValue(includeOffers),
		titleId: toValue(titleId),
		first: 8,
	}));

	const upcomingVariables = computed<GetSimilarUpcomingTitlesQueryVariables>(() => ({
		...baseVariables.value,
		filters: {
			excludeIrrelevantTitles: isPremium.value,
			...toValue(filters),
		},
	}));

	const queryOptions = { errorPolicy: 'all' } as const;

	function getQuery(isUpcoming: boolean) {
		return isUpcoming
			? useQuery<GetSimilarUpcomingTitlesQuery, GetSimilarUpcomingTitlesQueryVariables>(
					GetSimilarUpcomingTitlesDocument,
					upcomingVariables,
					queryOptions
			  )
			: useQuery<GetSimilarTitlesQuery, GetSimilarTitlesQueryVariables>(
					GetSimilarTitlesDocument,
					variables,
					queryOptions
			  );
	}

	const { onError, result, loading } = getQuery(toValue(isUpcoming));

	onError(error =>
		captureMessageForSentry(
			'[GraphQL Get Similar Titles Data]:',
			{ error, where: 'Component: useSimilarTitles' },
			'error'
		)
	);

	const releasedSimilarTitles = computed(() => {
		if (toValue(isUpcoming)) return [];

		const node = (<GetSimilarTitlesQuery>result.value)?.node as ReleasedSimilarTitlesNode;
		if (!node) return [];

		return (node.similarTitlesV2.edges?.map(edge => edge.node) ?? []) as ReleasedSimilarTitles<IncludeOffers>;
	});

	const similarUpcomingTitles = computed<UpcomingSimilarTitlesNode[]>(() => {
		if (!toValue(isUpcoming)) return [];

		return (<GetSimilarUpcomingTitlesQuery>result.value)?.newTitles.edges?.map(edge => edge.node) ?? [];
	});

	const similarTitles = computed(
		() =>
			(toValue(isUpcoming) ? similarUpcomingTitles.value : releasedSimilarTitles.value) as SimilarTitles<
				IsUpcoming,
				IncludeOffers
			>
	);

	const isEmpty = computed(() => similarTitles.value?.length < toValue(minTitles));

	return {
		/** Released or upcoming similar titles based on `isUpcoming`. */
		similarTitles,
		isEmpty,
		loading,
	};
}
