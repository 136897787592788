
import { Offer } from '@/@types/graphql-types';
import NewDesignModal from '@/components/modals/NewDesignModal.vue';
import { TitleDetailSeason } from '@/interfaces/title-details-graphql';
import PlexPlayer from '@/ui-components/PlexPlayer.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({ name: 'PlexPlayerModal', components: { NewDesignModal, PlexPlayer } })
export default class PlexPlayerModal extends Vue {
	@Prop() offer: Offer;
	@Prop() title: TitleDetailSeason;

	get titleName() {
		return this.title?.show?.content.title ?? this.title?.content.title;
	}

	get clip() {
		return {
			provider: 'PLEX',
			externalId: this.offer.id,
			sourceUrl: this.offer.standardWebURL,
		};
	}
}
